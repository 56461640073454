import React from "react"
import MaskedInput from "react-text-mask"
import emailjs from "emailjs-com"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Logo from "../../images/logo-metodologia-ead.png"

import Precos from "../Precos"

toast.configure()

const valores = [
  {
    matricula: "R$100,00",
    boletoUm: "12x de R$133,34",
    boletoDois: "18x de R$100,00",
    dinheiro: "R$1200,00",
    cartao: "12x de R$108,33",
    totalUm: "R$1.700,00",
    totalDois: "R$1.900,00",
    totalTres: "R$1.399,96",
  },
  {
    matricula: "R$100,00",
    boletoUm: "10x de R$80,00",
    boletoDois: "12x de R$75,00",
    dinheiro: "R$600,00",
    cartao: "12x de R$58,33",
    totalUm: "R$900,00",
    totalDois: "R$1.000,00",
    totalTres: "R$799,96",
  },
  {
    matricula: "R$100,00",
    boletoUm: "12x de R$300,00",
    boletoDois: "18x de R$211.11",
    dinheiro: "R$3.200,00",
    cartao: "12x de R$275,00",
    totalUm: "R$3.700,00",
    totalDois: "R$3.899,98",
    totalTres: "R$3.400,00",
  },
  {
    matricula: "R$100,00",
    boletoUm: "12x de R$216,67",
    boletoDois: "18x de R$155.55",
    dinheiro: "R$2.200,00",
    cartao: "12x de R$191,66",
    totalUm: "R$2.700,00",
    totalDois: "R$2.899,90",
    totalTres: "R$2.399,92",
  },
]

const CursoTecnico = () => {
  const [curso, setCurso] = React.useState("")

  const b = function () {
    if (curso === "Administração (Online)") {
      return valores[0]
    } else if (curso === "Computação Gráfica (Online)") {
      return valores[0]
    } else if (curso === "Contabilidade (Online)") {
      return valores[0]
    } else if (curso === "Finanças (Online)") {
      return valores[0]
    } else if (curso === "Guia de Turismo Regional (Online)") {
      return valores[0]
    } else if (curso === "Guia de Turismo INTERNACIONAL (Online)") {
      return valores[0]
    } else if (curso === "Informática para Internet (Online)") {
      return valores[0]
    } else if (curso === "Logística (Online)") {
      return valores[0]
    } else if (curso === "Marketing (Online)") {
      return valores[0]
    } else if (curso === "Meio Ambiente (Online)") {
      return valores[0]
    } else if (curso === "Secretariado Escolar (Online)") {
      return valores[0]
    } else if (curso === "Secretariado (Online)") {
      return valores[0]
    } else if (curso === "Segurança do Trabalho (Online)") {
      return valores[3]
    } else if (curso === "Agronegócios (Online)") {
      return valores[3]
    } else if (curso === "Edificações (Online)") {
      return valores[3]
    } else if (curso === "Eletrônica (Online)") {
      return valores[3]
    } else if (curso === "Mecânica (Online)") {
      return valores[3]
    } else if (curso === "Serviços Públicos (Online)") {
      return valores[3]
    } else if (curso === "Transações Imobiliárias (Online)") {
      return valores[1]
    } else if (
      curso === "Guia de Turismo Internacional/Nacional/Regional (Online)"
    ) {
      return valores[2]
    } else if (curso === "Guia de Turismo Nacional/Regional (Online)") {
      return valores[3]
    } else if (curso === "Eletrotécnica (Online)") {
      return valores[3]
    } else {
      return valores[4]
    }
  }

  console.log(curso)

  const a = b()

  function sendEmail(e) {
    e.preventDefault()

    emailjs
      .sendForm(
        "gmail",
        "template_x17q3xy",
        e.target,
        "user_VjStSBhZtjVYh6RRiS6gl"
      )
      .then(
        result => {
          toast.success(
            "Envio com sucesso. Sua matrícula será concluída assim que um dos nossos consultores entrar em contato!"
          )
        },
        error => {
          toast.info(
            "Ooops! Há algo de errado, por favor ligue para secretaria (82) 98719-5545."
          )
        }
      )
  }

  const showData = result => {
    for (const campo in result) {
      if (document.querySelector("#" + campo)) {
        document.querySelector("#" + campo).value = result[campo]
      }
    }
  }

  function findAddress(ev) {
    const { value } = ev.target
    if (value?.length !== 9) {
      return
    }

    fetch(`https://viacep.com.br/ws/${value}/json/`)
      .then(res => res.json())
      .then(data => showData(data))
  }

  return (
    <div className="flex flex-col w-full">
      <ToastContainer />
      <div className="flex-1 flex flex-col p-5 md:p-5 items-center">
        <img src={Logo} alt="Logo Metodologia EAD" className="w-24 md:w-36" />
        <h1 className="md:text-2xl text-color_one font-bold mt-1">
          Ficha de Inscrição Curso Técnico
        </h1>
        <div className="flex flex-col w-full md:px-8">
          <form onSubmit={sendEmail}>
            <div className="flex flex-col md:flex-row md:justify-between items-center">
              <div className="flex flex-col w-full">
                <h2 className="text-color_one text-xs mt-2">Nome Completo</h2>
                <input
                  className=" w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_nome"
                  required
                />
              </div>
              <div className="flex flex-col w-full md:w-1/3 ml-0 md:ml-2">
                <h2 className="text-color_one text-xs mt-2">Sexo</h2>
                <select
                  className=" w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_sexo"
                  required
                >
                  <option value="---">---</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                </select>
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div className="flex flex-col w-full">
                <h2 className="text-color_one text-xs mt-2">
                  Identidade
                  <span style={{ fontSize: "8px", marginLeft: 2 }}>
                    (Nº e Órgão Expedidor)
                  </span>
                </h2>
                <input
                  className=" w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_identidade"
                  required
                />
              </div>
              <div className="flex flex-col w-2/2 md:w-1/3 ml-2">
                <h2 className="text-color_one text-xs mt-2">CPF</h2>
                <MaskedInput
                  mask={[
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                  ]}
                  className=" w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_cpf"
                  required
                />
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div className="flex flex-col w-full">
                <h2 className="text-color_one text-xs mt-2">
                  Data de Nascimento
                </h2>
                <input
                  className=" w-full outline-none px-1 text-xs"
                  type="date"
                  name="from_datadenascimento"
                  required
                />
              </div>
            </div>

            <div className="flex justify-between">
              <div className="flex flex-col w-32">
                <h2 className="text-color_one text-xs mt-2">Cep</h2>
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
                  className=" w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_cep"
                  onBlur={findAddress}
                  id="cep"
                  required
                />
              </div>
              <div className="flex flex-col w-full ml-2">
                <h2 className="text-color_one text-xs mt-2">Logradouro</h2>
                <input
                  className="w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_logradouro"
                  id="logradouro"
                  required
                />
              </div>
              <div className="flex flex-col w-20 ml-2">
                <h2 className="text-color_one text-xs mt-2">Nº</h2>
                <input
                  className="w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_numero"
                />
              </div>
            </div>

            <div className="flex flex-col w-full">
              <h2 className="text-color_one text-xs mt-2">Complemento</h2>
              <input
                className=" w-full outline-none px-1 text-xs"
                type="text"
                name="from_complemento"
              />
            </div>

            <div className="flex justify-between">
              <div className="flex flex-col w-full">
                <h2 className="text-color_one text-xs mt-2">Bairro</h2>
                <input
                  className=" w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_bairro"
                  id="bairro"
                  required
                />
              </div>
              <div className="flex flex-col w-full ml-2">
                <h2 className="text-color_one text-xs mt-2">Cidade</h2>
                <input
                  className="w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_cidade"
                  id="localidade"
                  required
                />
              </div>
              <div className="flex flex-col w-28 ml-2">
                <h2 className="text-color_one text-xs mt-2">UF</h2>
                <input
                  className="w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_enderecouf"
                  id="uf"
                  required
                />
              </div>
            </div>

            <div className="flex flex-col w-full">
              <h2 className="text-color_one text-xs mt-2">Email</h2>
              <input
                className=" w-full outline-none px-1 text-xs"
                type="email"
                name="from_email"
                required
              />
            </div>

            <div className="flex flex-col md:flex-row md:justify-between items-center">
              <div className="flex flex-col w-full md:w-2/4">
                <h2 className="text-color_one text-xs mt-2">
                  Telefone Celular
                </h2>
                <MaskedInput
                  mask={[
                    "(",
                    /[1-9]/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  className=" w-full outline-none px-1 text-xs"
                  type="tel"
                  name="from_telefonecelular"
                  required
                />
              </div>
              <div className="flex flex-col w-full md:w-2/4 ml-0 md:ml-2">
                <h2 className="text-color_one text-xs mt-2">
                  Telefone Residencial
                </h2>
                <MaskedInput
                  mask={[
                    "(",
                    /[1-9]/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  className=" w-full outline-none px-1 text-xs"
                  type="tel"
                  name="from_telefoneresidencial"
                />
              </div>
              <div className="flex flex-col w-full md:w-2/4 ml-0 md:ml-2">
                <h2 className="text-color_one text-xs mt-2">
                  Telefone Comercial
                </h2>
                <MaskedInput
                  mask={[
                    "(",
                    /[1-9]/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  className=" w-full outline-none px-1 text-xs"
                  type="tel"
                  name="from_telefonecomercial"
                />
              </div>
            </div>

            <div className="flex flex-col w-full ">
              <h2 className="text-color_one text-xs mt-2">
                Qual curso técnico que você tem interesse?
              </h2>
              <select
                className=" w-full outline-none px-1 text-xs overflow-hidden"
                type="text"
                name="from_interesse"
                required
                value={curso}
                onChange={e => setCurso(e.target.value)}
              >
                <option className="overflow-ellipsis">Escolha uma opção</option>
                <option
                  className="overflow-ellipsis"
                  value="Administração (Online)"
                >
                  Administração (Online)
                </option>
                <option
                  className="overflow-ellipsis"
                  value="Guia de Turismo INTERNACIONAL (Online)"
                >
                  Guia de Turismo INTERNACIONAL (Online)
                </option>

                <option value="Guia de Turismo Regional (Online)">
                  Guia de Turismo Regional (Online)
                </option>
                <option value="Agronegócios (Online)">
                  Agronegócios (Online)
                </option>
                <option value="Guia de Turismo Internacional/Nacional/Regional (Online)">
                  Guia de Turismo Int./Nac./Reg. (Online)
                </option>
                <option value="Secretariado (Online)">
                  Secretariado (Online)
                </option>
                <option value="Finanças (Online)">Finanças (Online)</option>
                <option value="Marketing (Online)">Marketing (Online)</option>
                <option value="Transações Imobiliárias (Online)">
                  Transações Imobiliárias (Online)
                </option>
                <option value="Informática para Internet (Online)">
                  Informática para Internet (Online)
                </option>
                <option value="Contabilidade (Online)">
                  Contabilidade (Online)
                </option>
                <option value="Serviços Públicos (Online)">
                  Serviços Públicos (Online)
                </option>
                <option value="Mecânica (Online)">Mecânica (Online)</option>
                <option value="Secretariado Escolar (Online)">
                  Secretariado Escolar (Online)
                </option>
                <option value="Computação Gráfica (Online)">
                  Computação Gráfica (Online)
                </option>
                <option value="Guia de Turismo Nacional/Regional (Online)">
                  Guia de Turismo Nacional/Regional (Online)
                </option>
                <option value="Meio Ambiente (Online)">
                  Meio Ambiente (Online)
                </option>
                <option value="Logística (Online)">Logística (Online)</option>
                <option value="Segurança do Trabalho (Online)">
                  Segurança do Trabalho (Online)
                </option>
                <option value="Eletrônica (Online)">Eletrônica (Online)</option>
                <option value="Eletrotécnica (Online)">
                  Eletrotécnica (Online)
                </option>
                <option value="Edificações (Online)">
                  Edificações (Online)
                </option>
              </select>
            </div>

            <div className="flex flex-col w-full ">
              <h2 className="text-color_one text-xs mt-2">
                Forma de pagamento
              </h2>
              <select
                className=" w-full outline-none px-1 text-xs overflow-hidden"
                type="text"
                name="from_pagamento"
                required
              >
                <option className="overflow-ellipsis">Escolha uma opção</option>
                <option className="overflow-ellipsis" value="Boleto Opção 01">
                  Boleto Opção 01
                </option>
                <option value="Boleto Opção 02">Boleto Opção 02</option>
                <option value="À Vista">À Vista</option>
                <option value="Cartão de Crédito">Cartão de Crédito</option>
              </select>
            </div>

            <div className="flex flex-col items-center w-full mt-5">
              <div className="flex flex-col w-3/4">
                <input
                  className="inputButton text-sm"
                  type="submit"
                  value="Enviar Inscrição"
                />
              </div>
            </div>

            {!a ? (
              ""
            ) : (
              <Precos
                matricula={a.matricula}
                boletoOne={a.boletoUm}
                boletoTwo={a.boletoDois}
                aVista={a.dinheiro}
                credito={a.cartao}
                finalUm={a.totalUm}
                finalDois={a.totalDois}
                finalTres={a.totalTres}
              />
            )}

            <hr className="mt-3" />

            <div>
              <p className="text-xs mt-2">
                OBS: O cancelamento da inscrição com devolução de taxa somente
                será feito até 2 dias úteis antes do início do curso.
              </p>
            </div>

            <hr className="mt-2" />

            <div>
              <p className="text-xs mt-2">
                Documentos Necessários Ao Finalizar a inscrição enviar os
                seguintes documentos via whatsapp;
              </p>
              <ul>
                <li className="text-xs">• RG</li>
                <li className="text-xs">• CPF</li>
                <li className="text-xs">• TITULO ELEITORAL</li>
                <li className="text-xs">• COMPROVANTE DE RESIDÊNCIA</li>
                <li className="text-xs">• 02 FOTOS ¾</li>
              </ul>
              <p className="hidden md:flex text-xs mt-2">
                Enviar para secretaria Whatsapp = (82) 98719-5545
              </p>
              <p className="md:hidden text-xs mt-2">
                Enviar para secretaria Whatsapp
                <br /> (82) 98719-5545
              </p>
            </div>

            <hr className="mt-2" />

            <p className="text-xs mt-2">
              OBS: O CERTIFICADO SÓ SERÁ ENTREGUE COM A CÓPIA DE TODOS OS
              DOCUMENTOS ENTREGUES NO INSTITUTO
            </p>
          </form>
        </div>
      </div>

      <footer className="flex items-center place-items-center w-full h-6 ">
        <p style={{ fontSize: 10, marginLeft: 10 }}>
          &copy; {new Date().getFullYear()}, Metodologia EAD
        </p>
      </footer>
    </div>
  )
}

export default CursoTecnico
