import React from "react"

import * as S from './styled'

const Precos = ({ boletoOne, boletoTwo, aVista, credito, matricula, finalUm, finalDois, finalTres }) => {
  return (
    <div className="flex flex-col w-full items-center mt-5">
      <div className="gradient flex flex-col h-96 md:h-72 p-5 rounded-lg">
        <h3 className="block w-full text-lg font-bold text-color_one">
          Matrícula: <span className="text-red-700 text-2xl">{matricula}</span> +
        </h3>
        <hr />
        <ul>
          <li className="block w-full text-sm md:text-lg font-bold text-color_one mt-4">
            Boleto Opção 01:{" "} <S.Espaco />
            <span className="text-red-700 text-lg md:text-2xl">{boletoOne}</span> =
            <hr />
          </li>
          <li className="block w-full text-sm md:text-lg font-bold text-color_one mt-4">
            Boleto Opção 02:{" "} <S.Espaco />
            <span className="text-red-700 text-lg md:text-2xl">{boletoTwo}</span> =
            <hr />
          </li>

          <li className="block w-full text-sm md:text-lg font-bold text-color_one mt-4">
            À Vista: <S.Espaco />
            <span className="text-red-700 text-lg md:text-2xl">{aVista}</span>{" "}
            (débito ou boleto)
            <hr />
          </li>
          <li className="block w-full text-sm md:text-lg font-bold text-color_one mt-4">
            Cartão de Crédito:{" "} <S.Espaco />
            <span className="text-red-700 text-lg md:text-2xl">{credito}</span> =
          </li>
        </ul>
        <S.SpansWrapper>
        <span className="precoFinalUm">{finalUm}</span>
        <span className="precoFinalDois">{finalDois}</span>
        <span className="precoFinalTres">{finalTres}</span>
        </S.SpansWrapper>
      </div>
    </div>
  )
}

export default Precos
