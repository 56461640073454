import React from "react"
import Layout from "../components/Layout"
import CursoTecnico from "../components/Inscricoes/curso-tecnico"
import AnuncioCursoTecnico from "../components/Anuncios/curso-tecnico"

import { LeftWrapper, RightWrapper } from "../components/Layout/styled"

import SEO from "../components/seo"

const CursoTecnicoPage = () => (
  <Layout>
    <SEO title="Inscrição Curso Técnico" />
      <LeftWrapper>
        <CursoTecnico />
      </LeftWrapper>
      <RightWrapper>
        <AnuncioCursoTecnico />
      </RightWrapper>
  </Layout>
)

export default CursoTecnicoPage
