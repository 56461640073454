import styled from "styled-components"
import media from 'styled-media-query'

export const SpansWrapper = styled.div`
.precoFinalUm{
  display: block;
  position: absolute;
  top: 56px;
  right: -90px;

  
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;

  ${media.lessThan("small")`
  font-size: 11px;
  top: 68px;
  right: -20px;
  `}
}

.precoFinalDois{
  display: block;
  position: absolute;
  top: 105px;
  right: -90px;

  
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;

  ${media.lessThan("small")`
  font-size: 11px;
  top: 132px;
  right: -20px;
  `}
}

.precoFinalTres{
  display: block;
  position: absolute;
  top: 200px;
  right: -90px;

  
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;


  ${media.lessThan("small")`
  font-size: 11px;
  top: 260px;
  right: -20px;
  `}
}
`

export const Espaco = styled.br`
  display: none;

  ${media.lessThan("small")`
  display: flex;
  `}
`
